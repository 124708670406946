/* customization */
$theme-colors: (
    "primary": #fdb019,
    "info": #62B1F6,
    "success": #6be898,
    "danger": #d9534f,
    "warning": #f0ad4e,
    "dark": #4f5b66,
    "light": #f4f4f4
);

$font-family-base: 'Montserrat', 'Helvetica Neue', sans-serif;

$body-color: #4f5b66;

.form-control {
    font-size: 0.85rem!important;
}

.form-control.login-control {
    font-size: 1rem!important;
}

.breadcrumb {
    font-size: 0.85rem;
}

/** camera **/
.react-html5-camera-photo, .react-html5-camera-photo video, .react-html5-camera-photo img {
    width: 100%!important;
}

/** breadcrumb **/
.breadcrumb {
    background-color: map-get($theme-colors, "light")!important;
}

/** collapse **/
.ReactCollapse--collapse {
    transition: height 200ms;
}

/** checkbox **/
.checkbox-container label {
    display: block;
    position: relative;
    cursor: pointer;
    line-height: 1.5rem;
}

.checkbox-container label p {
    display: inline-block;
    margin: 0;
    line-height: 1.5rem;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

@each $color, $value in $theme-colors {
    .checkmark-#{$color} {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        vertical-align: middle;
    }

    .checkbox-container:hover input ~ .checkmark-#{$color} {
        background-color: map-get($theme-colors, "light");
        opacity: 0.8;
    }

    .checkbox-container:hover input:checked ~ .checkmark-#{$color} {
        background-color: $value;
    }

    .checkbox-container input:checked ~ .checkmark-#{$color} {
        background-color: $value;
    }

    .checkmark-#{$color}:after {
        content: "\f00c";
        font-family: "Line Awesome Free";
        font-weight: 900;
        color: #fff;
        position: absolute;
        display: none;
        font-size: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        text-align: center;
    }

    .checkbox-container input:checked ~ .checkmark-#{$color}:after {
        display: block;
    }
}

/** general **/
.dark-bg {
    background-color: $body-color;
}

.primary-bg {
    background-color: map-get($theme-colors, "primary");
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bordered {
    border-style: solid;
    border-color: rgba(98,113,127,0.3);
    border-width: 1px;
}

.borderless {
    border-style: none!important;
    border-width: 0!important;
}

.bordered-left {
    border-left-style: solid;
    border-left-color: rgba(98,113,127,0.3);
    border-left-width: 1px;
}

.bordered-top {
    border-top-style: solid;
    border-top-color: rgba(98,113,127,0.3);
    border-top-width: 1px;
}

.touchable {
    cursor: pointer;
    user-select: none;
}
.touchable:hover {
    opacity: 0.8;
}
.touchable:active {
    opacity: 0.2;
}

.btn {
    color: #fff !important;
}

.badge {
    color: #fff !important;
    font-weight: normal !important;
    font-size: 0.85rem !important;
}

@each $color, $value in $theme-colors {
    .btn.btn-outline-#{$color} {
        color: $value !important;
    }

    .btn.btn-outline-#{$color}:hover {
        color: #fff !important;
    }
}

.btn-light {
    color: $body-color !important;
}

/** TITLES **/
h1 {
    font-size: 1.3rem!important;
    text-align: left!important;
    font-weight: 700!important;
    margin-bottom: 1rem!important;
    margin-top: 0rem!important;
}
h2 {
    font-size: 1.1rem!important;
    text-align: center!important;
    font-weight: 700!important;
    margin-bottom: 0.75rem!important;
}
h3 {
    font-size: 0.9rem!important;
    text-align: left!important;
    font-weight: 700!important;
    margin: 0rem!important;
}
h4 {
    font-size: 0.9rem!important;
    text-align: left!important;
    font-weight: 700!important;
}
h5 {
    text-align: left!important;
    font-weight: 700!important;
    font-size: 0.77rem!important;
    margin-bottom: 0rem!important;
}
p {
    font-size: 0.85rem;
}
.label {
    font-weight: 600!important;
    font-size: 0.7rem!important;
    padding-bottom: 0.5rem;
    margin: 0rem;
}
.unbold {
    font-weight: 400!important;
}

/** MARGINS AND PADDINGS */
.standard-margin {
    margin-left: 20px;
    margin-right: 20px;
}

.standard-margin-2 {
    margin-left: 10px;
    margin-right: 10px;
}

.standard-margin-3 {
    margin-left: 3px;
    margin-right: 3px;
}

.vertical-margin {
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}

.vertical-margin-2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.vertical-margin-3 {
    margin-top: 3px;
    margin-bottom: 3px;
}

.standard-padding {
    padding-left: 20px;
    padding-right: 20px;
}

.standard-padding-2 {
    padding-left: 10px;
    padding-right: 10px;
}

.standard-padding-3 {
    padding-left: 3px;
    padding-right: 3px;
}

.vertical-padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

.vertical-padding-2 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.vertical-padding-3 {
    padding-top: 3px;
    padding-bottom: 3px;
}

.icon-container {
    min-width: 1.8rem;
    min-height: 1.8rem;
    max-width: 1.8rem;
    max-height: 1.8rem;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-Items: center;
}

.icon {
    font-weight: 900;
    font-size: 1rem;
}

.icon-btn-container {
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 50%;
    border-width: 0.25rem;
    border-style: solid;
    border-color: #cfd3d7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-btn {
    font-size: 1rem;
}

/* switch selector */
.switch-selector label {
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.7rem!important;
}

/* import bootstrap */
@import "~bootstrap/scss/bootstrap";